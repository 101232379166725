import React from 'react';

class Contact extends React.Component {
	render() {
		return (
			<div id="contact" className="section md-padding bg-grey">
				<div className="container">
					<div className="row">
						<div className="section-header text-center">
							<h2 className="title">Get in touch</h2>
						</div>
						<div className="col-sm-4">
							<div className="contact">
								<i className="fa fa-phone"></i>
								<h3>Phone</h3>
								<p>082 495 4125</p>
							</div>
						</div>

						<div className="col-sm-4">
							<div className="contact">
								<i className="fa fa-envelope"></i>
								<h3>Email</h3>
								<p>exoticcurtain@gmail.com</p>
							</div>
						</div>

						<div className="col-sm-4">
							<div className="contact">
								<i className="fa fa-map-marker"></i>
								<h3>Address</h3>
								<p>561 South Coast Road, Clairwood, Durban</p>
							</div>
						</div>
						<div className="col-md-8 col-md-offset-2">
							<form name="contact" className="contact-form" netlify="true" netlify-honeypot="bot-field" >
								<input type="text" className="input" placeholder="Name" />
								<input type="email" className="input" placeholder="Email" />
								<input type="text" className="input" placeholder="Subject" />
								<textarea className="input" placeholder="Message"></textarea>
								<input type="hidden" name="form-name" value="contact" />
								<button className="main-btn" type="submit">Send message</button>
							</form>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
export default Contact;