import React from 'react';
import * as contentful from 'contentful';

export default class Stockist extends React.Component {
	constructor() {
		super();
		this.state = { stockists: [] };
	}
	componentDidMount() {
		const client = contentful.createClient({
			space: 'mzp8p3b50e4b',
			accessToken: 'y4EZDhH6Bdg7y34Owm-ldUbCpg8Danpqy5fR2X74Ud0',
		});
		client
			.getEntries({
				content_type: 'stockist',
			})
			.then((response) => {this.setState({stockists: response.items})});
	}
	render() {
		return (
			<div id="stockist" className="section md-padding">
				<div className="container">
					<div className="row">
						<div className="section-header text-center">
							<h2 className="title">Stockists of </h2>
						</div>
						{this.state.stockists.map((stockist, i) => (
							<div className="col-md-4" key={i}>
								<a href={stockist.fields.link} target="_blank" rel="noreferrer noopener">
									<div className="stockist">
										<h3>{stockist.fields.title}</h3>
										<p>{stockist.fields.description}</p>
									</div>
								</a>
							</div>
						))}
					</div>
				</div>
			</div>
		);
	}
}
