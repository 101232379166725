import React from 'react';
import './App.css';

/* Sections */
import Navbar from './sections/navbar/navbar';
import About from './sections/about/about';
import Stockist from './sections/stockist/stockist';
import Gallery from './sections/gallery/gallery';
import Find from './sections/find/find';
import Contact from './sections/contact/contact';
import Footer from './sections/footer/footer';

class App extends React.Component {
	render() {
		return (
			<div className="App">
				<header id="home">
					<div className="bg-img" style={{ backgroundImage: "url('./img/img-14.jpeg')" }}>
						<div className="overlay"></div>
					</div>
					<Navbar />
					<About />
				</header>

				<Stockist/>

				<Gallery />

				<Find/>
				
				<Contact />

				<Footer />

				<div id="preloader">
					<div className="preloader">
						<span></span>
						<span></span>
						<span></span>
						<span></span>
					</div>
				</div>
			</div>
		);
	}
}

export default App;
