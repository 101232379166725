import React from 'react';

export default class Navbar extends React.Component {
	constructor() {
		super();
		this.state = { isToggle: false };
		this.toggleMenu = this.toggleMenu.bind(this);
	}
	toggleMenu() {
		this.setState({ isToggle: !this.state.isToggle });
	}
	render() {
		return (
			<nav id="nav" className={`navbar nav-transparent ${this.state.isToggle ? 'open' : ''}`} onClick={this.toggleMenu}>
				<div className="container">
					<div className="navbar-header">
						<div className="navbar-brand">
							<a href="#home" className="App-logo">
								Exotic Curtains & Homes
							</a>
						</div>

						<div className="nav-collapse" onClick={this.toggleMenu}>
							<span></span>
						</div>
					</div>

					<ul className="main-nav nav navbar-nav navbar-right">
						<li>
							<a href="#home">Home</a>
						</li>
						<li>
							<a href="#stockist">Stockists of</a>
						</li>
						<li>
							<a href="#gallery">Gallery</a>
						</li>
						<li>
							<a href="#find">Find us</a>
						</li>
						<li>
							<a href="#contact">Contact</a>
						</li>
					</ul>
				</div>
			</nav>
		);
	}
}
