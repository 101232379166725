
import React from 'react';

class Footer extends React.Component {
	render() {
		return (
			<footer id="footer" className="sm-padding bg-dark">
				<div className="container">
					<div className="row">
						<div className="col-md-12">
							<ul className="footer-follow">
								<li>
									<a href="https://www.facebook.com/ex0ticcurtains/" target="_blank" rel="noreferrer noopener">
										<i className="fa fa-facebook"></i>
									</a>
								</li>
								<li>
									<a href="https://www.instagram.com/ex0ticcurtains/" target="_blank" rel="noreferrer noopener">
										<i className="fa fa-instagram"></i>
									</a>
								</li>
							</ul>
							<div className="footer-copyright">
								<p>Copyright © 2020. All Rights Reserved. Designed by Devwood</p>
							</div>
						</div>
					</div>
				</div>
			</footer>
		);
	}
}
export default Footer;