import React, { Component } from 'react';

import * as contentful from 'contentful';

import DztImageGalleryComponent from 'reactjs-image-gallery';

export default class Gallery extends Component {
	constructor () {
		super()
		this.state = {images: []}
	}
	componentDidMount() {
		const client = contentful.createClient({
			space: 'mzp8p3b50e4b',
			accessToken: 'y4EZDhH6Bdg7y34Owm-ldUbCpg8Danpqy5fR2X74Ud0',
		});
		client.getAssets().then((assets) => {this.setState({images: assets.items})})
	}


	render() {
		var galleryImages = [];
		this.state.images.map(image => galleryImages.push({ url: image.fields.file.url, thumbUrl: image.fields.file.url }));
		
		return (
			<div id="gallery" className="section md-padding bg-grey">
				<div className="section-header text-center">
					<h2 className="title">Gallery</h2>
				</div>
				<DztImageGalleryComponent images={galleryImages} hideDownload={true} hideRotate={true} />
			</div>
		);
	}
}
