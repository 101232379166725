import React from 'react';

class Find extends React.Component {
	render() {
		return (
			<div id="find" className="section md-padding">
				<div className="container">
					<div className="row">
						<div className="section-header text-center">
							<h2 className="title">Find us</h2>
						</div>
						<div className="col-12">
							<div className="gmap_canvas">
								<iframe
									width="100%"
									height="500"
									title="exoticmap"
									id="gmap_canvas"
									src="https://maps.google.com/maps?q=561%20south%20coast%20road&t=&z=15&ie=UTF8&iwloc=&output=embed"
									frameBorder="0"
									scrolling="no"
									marginHeight="0"
									marginWidth="0"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
export default Find;