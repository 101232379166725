import React from 'react';

class About extends React.Component {
	render() {
		return (
			<div className="home-wrapper">
				<div className="container">
					<div className="row">
						<div className="col-md-10 col-md-offset-1">
							<div className="home-content">
								<h1 className="white-text">Welcome to Exotic Curtains & Homes</h1>
								<p className="white-text">Specialising in curtains, curtain accessories, curtain rods, curtain rails, palmets and blinds</p>
								<p className="white-text">
									<strong>We will measure, quote, sew & install</strong>
								</p>
								<p className="white-text">
									<strong>FREE measurements can be done for quotes</strong>
								</p>
								<p className="white-text">We also stock and sell blankets, bedspreads, sheets, towels, comforters and crockery</p>
								<p className="white-text">Find us at 561 South Coast Road, Clairwood, Durban</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
export default About
;